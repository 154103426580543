import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Last week Google held its annual I/O conference for developers. Some of the announcements that excited us at Dashbot the most were surrounding the Google Assistant. Voice is more than an emerging platform. People are becoming more and more `}<a parentName="p" {...{
        "href": "https://www.stonetemple.com/voice-usage-study-2018/"
      }}>{`comfortable using voice in public spaces`}</a>{` and usage is rising among all demographics. Here are the ways that the Google Assistant is evolving that we can’t wait to see in everyday life.`}</p>
    <h2>{`Google Assistant Speeds Up`}</h2>
    <p>{`At I/O, Google revealed a version of the Assistant that runs ten times faster than the previous generation thanks to a lighter AI model that can listen and understand speech straight from the device. Before this upgrade, your device had to send data to remote servers for processing. Scott Huffman, the VP of Engineering for the Google Assistant, demonstrated on the I/O stage how this new iteration is able to operate instantly at the sound of your voice, even offline. What’s more, users will be able to string together a list of commands across various apps `}<a parentName="p" {...{
        "href": "https://www.pocket-lint.com/apps/news/google/136940-google-i-o-event-watch-announcements"
      }}>{`without invoking “OK Google” every time`}</a>{`, which resembles a more realistic conversation. Faster responsiveness and a proper conversational experience could give Google the competitive edge over other voice assistants like Amazon’s Alexa.`}</p>
    <h2>{`On the Road`}</h2>
    <p>{`This SuperBot Dashbot wanted to know more about the future of voice, so we invited speakers to discuss where they think this space was going. Our speakers really focused in on what a huge role the car would play as the `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2019/04/26/superbot-2019-future-of-voice/"
      }}>{`last land grabs left in the digital space`}</a>{`. Google seems to be on the same page. `}<a parentName="p" {...{
        "href": "https://techcrunch.com/2019/05/07/google-assistant-gets-driving-mode/"
      }}>{`Driving mode`}</a>{` is coming to the Google Assistant, enabling more hands free interaction with your device. This new feature brings relevant activities such as navigation and messages front and center, creating a safer driving environment with less fumbling with your phone. Google will also be releasing a feature that makes it possible to remotely adjust your car settings through the Assistant.`}</p>
    <h2>{`Google Duplex Hits the Web`}</h2>
    <p>{`Google made a splash at last year’s I/O with the debut of `}<a parentName="p" {...{
        "href": "https://www.theverge.com/2018/12/5/18123785/google-duplex-how-to-use-reservations"
      }}>{`Duplex`}</a>{`, an AI that will call restaurants and make reservations on your behalf. At this year’s I/O, Google announced an expansion on this feature: Duplex will be available on the web. When Duplex made its first appearance, it was available exclusively on the Pixel phones and was only capable of making restaurant reservations. The first use cases for the web version will be `}<a parentName="p" {...{
        "href": "https://techcrunch.com/2019/05/07/google-is-bringing-ai-assistant-duplex-to-the-web/"
      }}>{`purchasing movie tickets and car rentals`}</a>{`.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to understand user behaviors, optimize response effectiveness, and increase user satisfaction through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and broadcast messaging for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales/?ref=blog+google+io"
      }}><br parentName="a"></br>
        {`Contact us for a demo `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      